
const uploadGuidelines =  {
	en: `
		<p><b>Guidelines for submitting photos of assignments</b></p>
		<ol>
			<li>If you are making a photo, please stand directly over, or across, from your work or design, not at an angle.
				Make sure the lighting is good and the complete block or design fits in the photo.</li>
			<li>For your pattern making assignments, your patterns should be drafted in measurements for practice Case A, unless otherwise stated in the assignment:<br>
				<ul>
					<li>Please upload photos of 2 PHASES: A. the Block completely developed BEFORE CUTTING and enclosed in the rectangular frame of the construction (make sure all 4 corners of the rectangle are visible in the photo), and B. the Block completed with
						all the changes and parts: facings, yokes, collars, trimmings etc. including seam allowances, AFTER CUTTING.
						(For information and help see lesson A9 of Pattern Construction).</li><li>Make sure you mark and label all
						pieces clearly and sufficiently. The measurements used for the block should be also on the photo.</li>
					<li>Draw a 10x10cm square clearly in the middle of your photo-frame for scale reference to provide your tutor with a yardstick or benchmark
						when reviewing your work.</li>
				</ul>
			</li>
			<li>If you are photographing a Fashion Design drawing, place it always over the frame of the basic axes of the figure
				with the central line, as well as the shoulder, waist, hip, knie and ankle lines of the respective figure pose.</li>
			<li>If you are photographing a sewn piece of garment, please submit 3 photos: one of the right side of the garment piece,
				on of the inside-out of the garment piece and a close-up of the seams.</li><li>Make sure that all the lines and/or
				seams are clearly visible in the photo or scan that you will send to your tutor. Review the photo yourself and make
				sure all the details are visible.</li>
			<li>Whether for a question to your tutor or for an assignment to correct, save your photos in .jpg or .png format between 300KB and 500KB large.</li>
			<li>If you are uploading an assignment your file will be automatically named and numbered in our system.</li>
			<li>If you are asking a question and send a photo or file as an attachment, please make sure to name the file with your name and question for easy access. Our server is set to reject emails larger than 2MB in total. So we recommend that you keep your files closer to the minimum limit.</li>
			<li>Please avoid making close-up photos of details, it is better to have clear photos of the whole garment, block or design so that our tutors can zoom in on any detail they require.</li>
			<li>Finally, please avoid making collages of different photos in a single file. If there are items of different perspective or scale in a single photo we will not be able to process or evaluate your assignment.  You can upload more than one photos for each assignment.</li>
		</ol>
		<p>If you follow these guidelines we will be able to process and review your work much quicker and provide you with our feedback
			as soon as possible. Thank you for your cooperation.</p>
	`,
	de: `
		<p><b>Richtlinien für LEK-Kursaufgaben und Fragen an deine Tutorin</b></p>
		<p>In deinem Learning-by-Doing Kurs bitte möglichst <b>jede</b> Lektion des Kurses anfertigen, um das Beste aus dem Kursinhalt herauszuholen.</p>
		<p>Wenn du es möchtest, können wir dein Lernen unterstützen, indem wir bestimmte Aufgaben von dir auswerten bzw. deine Fragen beantworten. Zur Auswertung bitte <b>Fotos von den s.g. LEK-Kursaufgaben hochladen</b>. Deine Tutorin wird diese auswerten und wenn nötig korrigieren. Die Korrekturen werden mit Hinweisen unter deinen eigenen Aufgaben hochgeladen, sodass du sie für dich herunterladen kannst.</p>
		<p>Beim Fotografieren bitte:</p>
		<ol>
			<li>Deine Arbeit soweit möglich <b>nicht im schrägen Winkel</b>, sondern direkt darüber- oder gegenüberstehend fotografieren.</li>
			<li>
				Checkliste für <b>Schnittmuster-Aufgaben</b>:
				<ol style="list-style-type:lower-alpha;">
					<li>Alle Grund- und Modellschnitte bitte das erste Mal mit den mit den empfohlenen Übungsmaßen immer erstellen, bzw. zur Auswertung als LEK hochladen. Für Damen das ist die <b>Übungsmaßtabelle Fall A</b> und bei Herren und Kindern werden die Übungsmaßen zugeschickt.</li>
					<li>Schnittmuster am besten <b>auf eine Wand oder Tür kleben</b>, direkt gegenüber auf Augenhöhe stehen und so fotografieren. Alternativ ganz flach auf den Boden legen und direkt darüberstehen.</li>
					<li>Vorderteil (VT) und Rückenteil (RT) werden meistens von der Konstruktion her in ein <b>geschlossenes Rechteck eingezeichnet</b>. Dieses Rechteck soll bitte mit <b>allen 4 rechten Ecken</b> auf dem Foto sichtbar sein.  So bleiben Seitennähte deckungsgleich und die Auswertung wird schneller und präziser.</li>
					<li>Ein <b>10x10cm Kontrollquadrat als Maßstab</b> senkrecht zum Schnitt und etwa mittig im Bild platzieren. Darin bitte alle Konstruktionsmaße für den Schnitt klar und deutlich schreiben.</li>
					<li>Für eine präzise Auswertung brauchen wir <b>ein Foto</b> vom vollentwickelten Schnitt UNBEDINGT <b>VOR DEM AUSSCHNEIDEN</b> und auch NACH DEM AUSSCHNEIDEN.</li>
					<li>Ausgeschnittene Teile inkl. aller Anpassungen, Belege, Besätze usw. eventuell auch Nahtzugaben sollen für ein Foto soweit möglich gerade nebeneinander liegen und nach der <b>gleichen x-y-Achse</b> ausgerichtet werden.</li>
					<li>Alle Teile <b>gut beschriften</b> (VM, HM, Längen, Konstruktionsmaße usw.).</li>
				</ol>
			</li>
			<li>
				Checkliste für alle Fotos <b>sowohl für die LEK auch für Fragen an die Tutorin</b>:
				<ol style="list-style-type:lower-alpha;">
					<li>Ausreichende <b>Beleuchtung</b> ohne Schattenwurf.</li>
					<li>Scharfe und <b>deutliche Linien und Beschriftungen</b> (inkl. 4 rechten Ecken und Kontrollquadrat bei Schnittmustern).</li>
					<li>Gesamtes Werk (Schnitt, Design, Genähtes) auf das Foto passend.</li>
					<li><b>Keine Nahaufnahmen</b> von Details.</li>
					<li>Vor dem Hochladen bitte das Foto selbst überprüfen.</li>
				</ol>
			</li>
			<li><b>Dateiname:</b> wird automatisch vom System benannt.</li>
			<li><b>Dateiformat:</b> .JPG oder .PNG. Bitte keine .PDF oder HEIC.</li>
			<li><b>Dateigröße:</b> bitte 300KB-500KB. Dateien größer als 2MB sind unzustellbar.</li>
			<li>Bei LEK-Kursaufgaben mit mehreren Teilen können <b>mehrere Fotos</b> hochgeladen werden. Bei Schnittmustern bitte VT und RT zusammen fotografieren und hochladen.</li>
			<li>Bitte <b>keine Collagen</b> von Fotos mit verschiedenen Perspektiven bzw. unterschiedlichem Maßstab hochladen. Diese können leider nicht aussagekräftig ausgewertet werden.</li>
			<li><b>Nachricht an die Tutorin:</b> Die gesamte Nachricht inkl. allen Dateien muss unter 2MB sein.</li>
			<li>Beispiel für Dateinamen bei Nachrichten an die Tutorin: "Karla Duesentrieb, B5 Bluse mit rechteckiger Passe VT". Keine Sonderzeichen!</li>
		</ol>
		<p><b>ACHTUNG! Die LEK-Kursaufgaben entsprechend deinem Lerntempo hochladen. Bitte keine Sammelaufgaben einreichen! So vermeidest du Wiederholungsfehler und bekommst du unser Feedback schneller.</b></p>
	`,
	fr: `
		<p><b>Consignes pour soumettre des photos de devoirs</b></p>
		<ol>
			<li>Quand vous faites une photo, veuillez vous tenir directement au-dessus ou en face de votre travail ou de votre dessin, et non en biais. Assurez-vous que l'éclairage est bon et que le patron ou le dessin complet tient bien sur la photo.</li>
			<li>Pour vos devoirs de Modélisme, vos patrons doivent être rédigés en mesures pour le cas pratique A, sauf indication contraire dans le devoir :<br>
				<ul>
					<li>Veuillez télécharger des photos de 2 PHASES : A. le patron complètement développé AVANT DE COUPER et enfermé dans le cadre rectangulaire de la construction (assurez-vous que les 4 coins du rectangle sont visibles sur la photo), et B. le Bloc terminé avec
						toutes les modifications et pièces : parementures, empiècements, cols, passementerie etc. y compris les marges de couture, APRÈS COUPE.
						(Pour plus d'informations et d'aide, voir la leçon A9 de Construction de patrons).</li>
					<li>Assurez-vous de marquer et d'étiqueter tous morceaux clairement et suffisamment. Les mesures utilisées pour le patron doivent également figurer sur la photo.</li>
					<li>Dessinez clairement un carré de 10 x 10 cm au milieu de votre cadre photo pour référence à l'échelle afin de fournir à votre tuteur un critère ou une référence lors de la révision de votre travail.</li>
				</ul>
			</li>
			<li>Pour vos devoirs de Stylisme, placez le dessin et la figurine toujours sur le cadre des axes de base de la figure avec la ligne centrale, ainsi que les lignes d'épaule, de taille, de hanche, de genou et de cheville de la pose de la figure respective.</li>
			<li> Pour vos devoirs de Couture, veuillez soumettre 3 photos : une du côté droit du vêtement, sur l'envers de la pièce de vêtement et un gros plan des coutures.</li>
			<li>Assurez-vous que toutes les lignes et/ou les coutures sont clairement visibles sur la photo ou le scan que vous enverrez à votre tuteur. Examinez la photo vous-même et faites assurez-vous que tous les détails sont visibles.</li>
			<li>Que ce soit pour une question à votre tuteur ou pour un devoir à corriger, enregistrez vos photos au format .jpg ou .png entre 300Ko et 500Ko de large.</li>
			<li>Si vous téléchargez un devoir, votre fichier sera automatiquement nommé et numéroté dans notre système.</li>
			<li>Si vous posez une question et envoyez une photo ou un fichier en pièce jointe, assurez-vous de nommer le fichier avec votre nom et votre question pour un accès facile. Notre serveur est configuré pour rejeter les messages de plus de 2 Mo au total. Nous vous recommandons donc de conserver vos fichiers plus près de la limite minimale. </li>
			<li>Veuillez éviter de faire des photos en gros plan des détails, il est préférable d'avoir des photos claires de l'ensemble du vêtement, du bloc ou du design afin que nos tuteurs puissent zoomer sur les détails dont ils ont besoin.</li>
			<li>Enfin, évitez de faire des collages de différentes photos dans un même fichier. S'il y a des éléments de perspective ou d'échelle différente dans une même photo, nous ne serons pas en mesure de traiter ou d'évaluer votre mission. Vous pouvez télécharger plusieurs photos pour chaque devoir.</li>
		</ol>
		<p>Si vous suivez ces consignes, nous serons en mesure de traiter et d'examiner votre travail beaucoup plus rapidement et de vous faire part de nos commentaires. dès que possible. Merci pour votre coopération.</p>
	`,
	el: `
		<p><b>Οδηγίες για την υποβολή των εργασιών ως φωτογραφίες </b></p>
		<ol>
			<li>Όταν τραβάς τη φωτογραφία της εργασίας σου, στάσου ακριβώς πάνω ή απέναντι από την εργασία ή το σχέδιό, όχι υπό γωνία. Βεβαιώσου ότι ο φωτισμός είναι καλός και ότι ολόκληρο το πατρόν ή το σχέδιο χωράει μέσα στα όρια της φωτογραφίας.</li>
			<li>Για τις εργασίες πατρόν, τα πατρόν σου θα πρέπει να είναι στα μέτρα για εξάσκηση Περίπτωση Α, εκτός εάν ορίζεται διαφορετικά στην εργασία:<br>
				<ul>
					<li>Ανέβασε φωτογραφίες από 2 ΦΑΣΕΙΣ εργασίας: Α. το Πατρόν με όλες τις αντικαταστάσεις ΠΡΙΝ ΤΟ ΚΟΨΕΙΣ έτσι ώστε να περικλείεται στο ορθογώνιο πλαίσιο της κατασκευής (βεβαιώσου ότι και οι 4 γωνίες του ορθογωνίου φαίνονται στη φωτογραφία) και Β. το Πατρόν με
						όλα τα κομμάτια: όψεις, ωμίτες, γιακάδες, διακοσμητικά κ.λ.π. μαζί με περιθωρία ραφής, ΜΕΤΑ ΤΗΝ ΚΟΠΗ.
						(Για πληροφορίες και βοήθεια, ανάτρεξε στο μάθημα A9 της κατασκευής προτύπων).</li>
					<li>Βεβαιώσου ότι έχετε σημειώσει όλες τις απαραίτητες πληρφορίες στα κομμάτια καθαρά και επαρκώς. Τα μέτρα κατασκευής θα πρέπει να αναγράφονται πάντα στο πατρόν.</li>
					<li>Σχεδίασε ένα τετράγωνο 10x10 cm καθαρά στη μέση του πλαισίου που θα φωτογραφηθεί για να έχουμε την σωστή κλίμακακατά την αξιολόγ ηση της εργασίας σου.</li>
				</ul>
			</li>
			<li>Εάν φωτογραφίζεις ένα Σκίτσο ή μια Φιγούρα Μόδας, τοποθέτησέ το πάντα πάνω στο πλαίσιο των βασικών αξόνων της φιγούρας με την κεντρική γραμμή, καθώς και τις γραμμές ώμου, μέσης, γοφών, γόνατος και αστραγάλου της αντίστοιχης φιγούρας.</li>
			<li>Εάν φωτογραφίζεις ένα ραμμένο κομμάτι, ανέβασε 3 φωτογραφίες: μία από την καλή πλευρά του ρούχου, μία από την ανάποδη του ρούχου και μια κοντινή λήψη των ραφών.</li><li>Βεβαιώσου ότι όλες οι γραμμές ή/και  οι ραφές είναι ευδιάκριτες στη φωτογραφία και ότι το μέγεθος της φωτογραφίας είναι αρκετό. Έλεγξε τη φωτογραφία και βεβαιώσου ότι όλες οι λεπτομέρειες είναι ορατές.</li>
			<li>Είτε στέλνεις μια ερώτηση στη δασκάλα σου είτε ανεβάζείς μια εργασία για διόρθωση, παρακαλούμε να αποθηκεύεις τις φωτογραφίες σε μορφή .jpg ή .png μεγέθους μεταξύ 300 KB και 500 KB.</li>
			<li>Όταν υποβάλεις μια εργασία, το αρχείο θα ονομαστεί και θα αριθμηθεί αυτόματα στο σύστημά μας.</li>
			<li>Εάν υποβάλεις μια ερώτηση και στέλνεις μαζί και μια φωτογραφία ή ένα αρχείο ως συνημμένο, φρόντισε να ονομάσεις το αρχείο με το όνομά σου και ένα στοιχείο από την ερώτησή σου για εύκολη πρόσβαση. Ο server μας έχει ρυθμιστεί να απορρίπτει μηνύματα μεγαλύτερα από 2 MB συνολικά. Συνιστούμε λοιπόν να κρατάτε τα αρχεία σας πιο κοντά στο ελάχιστο όριο.</li>
			<li>Αποφύγετε να τραβάτε κοντινές λήψεις λεπτομερειών, είναι καλύτερα να έχετε καθαρές φωτογραφίες ολόκληρου του ενδύματος, του πατρόν ή του σχεδίου, έτσι ώστε οι δασκάλες μας να μπορούν να κάνουν μεγέθυνση σε οποιαδήποτε λεπτομέρεια θέλουν.</li>
			<li>Τέλος, αποφύγετε τη δημιουργία κολάζ διαφορετικών φωτογραφιών σε ένα μόνο αρχείο. Εάν υπάρχουν στοιχεία διαφορετικής προοπτικής ή κλίμακας σε μία φωτογραφία, δεν θα μπορέσουμε να επεξεργαστούμε ή να αξιολογήσουμε την εργασία σου. Μπορείς να ανεβάσεις περισσότερες από μία φωτογραφίες για κάθε εργασία.</li>
		</ol>
		<p>Εάν ακολουθήσεις αυτές τις οδηγίες, θα μπορέσουμε να επεξεργαστούμε και να ελέγξουμε την εργασία σου πολύ πιο γρήγορα και να σου παρέχουμε τα σχόλιά μας όσο το δυνατόν συντομότερα. Ευχαριστούμε για τη συνεργασία.</p>
	`,
	he: `
		<p><b>הנחיות להגשת תמונות שיעורי בית</b></p>
		<ol>
			<li>כשתצלם תמונה, עמוד ישירות מעל או מול העבודה או הציור שלך, לא בזווית.
			ודא שהתאורה טובה והדוגמה או העיצוב השלם משתלבים היטב בתמונה.</li>
			<li>עבור שיעורי הבית של הכנת דפוסים, הדפוסים שלך צריכים להיות כתובים במידות עבור מקרה מעשי א', אלא אם צוין אחרת בשיעורי הבית:<br>
				<ul>
					<li>נא להעלות תמונות של 2 שלבים: א. התבנית שפותחה במלואה לפני החיתוך וסגורה במסגרת המלבנית של המבנה (ודא שכל 4 הפינות של המלבן גלויות בתמונה), וב. הבלוק סיים עם
						כל השינויים והחלקים: חיפויים, עוולים, צווארונים, גזרות וכו'. כולל קצבאות תפר, לאחר חיתוך.
						(למידע נוסף ועזרה, ראה שיעור בניית דפוסים A9).</li>
					<li>הקפד לסמן ולתייג את כל החלקים בצורה ברורה ומספקת. המידות המשמשות לדוגמא חייבות להופיע גם בתמונה.</li>
					<li>צייר בצורה ברורה ריבוע בגודל 10 על 10 ס"מ באמצע מסגרת התמונה שלך לצורך התייחסות בקנה מידה כדי לספק למורה שלך קנה מידה או התייחסות
						בעת סקירת העבודה שלך.</li>
				</ul>
			</li>
			<li>עבור שיעורי הבית שלך בעיצוב, הנח את הציור והדמות תמיד על המסגרת של צירי הבסיס של הדמות
				עם קו האמצע, כמו גם את קווי הכתפיים, המותניים, הירך, הברך והקרסול של תנוחת הדמות המתאימה.</li>
			<li>עבור מטלות התפירה שלך, נא לשלוח 3 תמונות: אחת מהצד הימני של הבגד,
				בצד הלא נכון של הבגד ותקריב של התפרים.</li>
			<li>ודא שכל הקווים ו/או התפרים נראים בבירור בתמונה או בסריקה שתשלח למורה שלך. בחנו את התמונה בעצמכם ועשו
				ודא שכל הפרטים גלויים.</li>
			<li>בין אם מדובר על שאלה למורה שלך או לצורך תיקון של מטלה, שמור את התמונות שלך בפורמט .jpg או .png ברוחב של 300Kb עד 500Kb.</li>
			<li>אם תעלה מטלה, הקובץ שלך יקבל שם וממוספר אוטומטית במערכת שלנו.</li>
			<li>אם אתה שואל שאלה ושולח תמונה או קובץ כקובץ מצורף, הקפד לתת שם לקובץ עם השם והשאלה שלך לגישה נוחה. השרת שלנו מוגדר לדחות הודעות מעל 2MB בסך הכל. לכן אנו ממליצים לשמור את הקבצים שלך קרוב יותר למגבלה המינימלית.</li>
			<li>אנא הימנע מצילום תקריב של פרטים, עדיף שיהיו לך תמונות ברורות של כל הבגד, הבלוק או העיצוב כדי שהמורים שלנו יוכלו להתקרב לפרטים שהם צריכים.</li>
			<li>לבסוף, הימנע מיצירת קולאז'ים של תמונות שונות באותו קובץ. אם יש אלמנטים של פרספקטיבה או קנה מידה שונים באותה תמונה, לא נוכל לעבד או להעריך את המטלה שלך. אתה יכול להעלות תמונות מרובות עבור כל מטלה.</li>
			<li>אם תפעל לפי ההנחיות האלה, נוכל לעבד ולבדוק את עבודתך מהר הרבה יותר ולספק לך משוב.
				בְּהֶקְדֵם הַאֶפְשַׁרִי. תודה על שיתוף הפעולה.</li>
			<li>ה או לדגם יש יותר משלב אחד ולצורך זה תזדקקי ל 2-3 צילומים זה יתקבל, אבל צילומים של שיעורים או קורסים אחרים לא יתקבלו.</li>
		</ol>
		<p>אם תעבדי ותמלאי אחר הוראות אלו יהיה לנו קל ומהיר לבדוק את העבודות ולחזור אלייך עם תשובות והערות בהתאם. תודה על תשומת הלב ושיתוף הפעולה.</p>
	`,
};

export default uploadGuidelines;

